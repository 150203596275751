import { formatCurrency } from "../../../../helpers/dataHelper/currentFormatter";

export default function Members(props) {
    const calculateAdvisoryFee = (advisoryFee, banks, status) => {
        if (status === "Completed") {
            if (advisoryFee?.type === "fixed") {
                return parseInt(advisoryFee?.amount);
            } else if (advisoryFee?.type === "percentage") {
                let total = 0;
                banks?.forEach((b) => (total += parseInt(b.cashOut)));
                return (total * parseFloat(advisoryFee?.amount)) / 100;
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    };

    return (
        <>
            <h2 className="section-title">{props?.heading || "Members"}</h2>

            {props?.members?.length > 0 && (
                <div className="member-wrapper flex column-direction content-justify-center">
                    <ul className="list-style-none flex flex-wrap">
                        {props?.members.map(
                            (
                                {
                                    memberName,
                                    pendingCases,
                                    allCases,
                                    allPendingCases,
                                },
                                index
                            ) => {
                                return (
                                    <li key={index}>
                                        <div className="member-text">
                                            <h2>{memberName}</h2>
                                            <section>
                                                <ul className="list-style-none">
                                                    <li className="flex items-center">
                                                        <strong>
                                                            Pending Cases
                                                        </strong>
                                                        <span className="ml-auto">
                                                            {pendingCases}
                                                        </span>
                                                    </li>
                                                    <li className="flex items-center">
                                                        <strong>
                                                            Bank Approved Cases
                                                        </strong>
                                                        <span className="ml-auto">
                                                            {allCases?.filter(
                                                                (e) =>
                                                                    e.status ===
                                                                        "Completed" &&
                                                                    !(
                                                                        e.status ===
                                                                            "Completed" &&
                                                                        e
                                                                            ?.financialInstitutes
                                                                            ?.length ===
                                                                            0
                                                                    ) &&
                                                                    !(
                                                                        e.status ===
                                                                            "Completed" &&
                                                                        e?.institutes?.find(
                                                                            (
                                                                                i
                                                                            ) =>
                                                                                i.amount &&
                                                                                !i.decision
                                                                        )
                                                                    ) &&
                                                                    !(
                                                                        e.status ===
                                                                            "Completed" &&
                                                                        e?.institutes?.find(
                                                                            (
                                                                                i
                                                                            ) =>
                                                                                i.amount &&
                                                                                i.approvedAmount &&
                                                                                !i.clientDecision
                                                                        )
                                                                    )
                                                            )?.length || 0}
                                                        </span>
                                                    </li>
                                                    <li className="flex items-center">
                                                        <strong>
                                                            Pending Cases Loan
                                                            Amount
                                                        </strong>
                                                        <span className="ml-auto">
                                                            {formatCurrency(
                                                                allPendingCases?.reduce(
                                                                    (
                                                                        total,
                                                                        cs
                                                                    ) =>
                                                                        total +
                                                                        parseInt(
                                                                            cs?.loanAmount ||
                                                                                "0"
                                                                        ),
                                                                    0
                                                                ),
                                                                true
                                                            )}
                                                        </span>
                                                    </li>
                                                    <li className="flex items-center">
                                                        <strong>
                                                            Bank Approved Cases
                                                            Loan Amount
                                                        </strong>
                                                        <span className="ml-auto">
                                                            {formatCurrency(
                                                                allCases
                                                                    ?.filter(
                                                                        (e) =>
                                                                            e.status ===
                                                                                "Completed" &&
                                                                            !(
                                                                                e.status ===
                                                                                    "Completed" &&
                                                                                e
                                                                                    ?.financialInstitutes
                                                                                    ?.length ===
                                                                                    0
                                                                            ) &&
                                                                            !(
                                                                                e.status ===
                                                                                    "Completed" &&
                                                                                e?.institutes?.find(
                                                                                    (
                                                                                        i
                                                                                    ) =>
                                                                                        i.amount &&
                                                                                        !i.decision
                                                                                )
                                                                            ) &&
                                                                            !(
                                                                                e.status ===
                                                                                    "Completed" &&
                                                                                e?.institutes?.find(
                                                                                    (
                                                                                        i
                                                                                    ) =>
                                                                                        i.amount &&
                                                                                        i.approvedAmount &&
                                                                                        !i.clientDecision
                                                                                )
                                                                            )
                                                                    )
                                                                    ?.reduce(
                                                                        (
                                                                            total,
                                                                            cs
                                                                        ) => {
                                                                            cs?.institutes?.forEach(
                                                                                (
                                                                                    b
                                                                                ) => {
                                                                                    if (
                                                                                        b?.decision ===
                                                                                            "Accepted" &&
                                                                                        b?.approvedAmount
                                                                                    ) {
                                                                                        total +=
                                                                                            parseInt(
                                                                                                b?.approvedAmount ||
                                                                                                    "0"
                                                                                            );
                                                                                    }
                                                                                }
                                                                            );
                                                                            return total;
                                                                        },
                                                                        0
                                                                    ),
                                                                true
                                                            )}
                                                        </span>
                                                    </li>
                                                    <li className="flex items-center">
                                                        <strong>
                                                            Advisory Fee
                                                        </strong>
                                                        <span className="ml-auto">
                                                            {formatCurrency(
                                                                allCases?.reduce(
                                                                    (
                                                                        total,
                                                                        cs
                                                                    ) =>
                                                                        total +
                                                                        calculateAdvisoryFee(
                                                                            cs?.advisoryFee,
                                                                            cs?.institutes?.filter(
                                                                                (
                                                                                    e
                                                                                ) =>
                                                                                    e.cashOut
                                                                            ),
                                                                            cs?.status
                                                                        ),
                                                                    0
                                                                ),
                                                                true
                                                            )}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </section>
                                        </div>
                                    </li>
                                );
                            }
                        )}
                    </ul>
                    {/* <Link className="flex items-center">
                        See More <i className="fa fa-angle-right"></i>
                    </Link> */}
                </div>
            )}
        </>
    );
}
