import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import blueForm from "../../../../assets/images/blue-form.svg";
import dollarIcon from "../../../../assets/images/dollar.svg";
import { formatCurrency } from "../../../../helpers/dataHelper/currentFormatter";
import { setRoute } from "../../../../redux/route/routeSlice";
import { setSidebar } from "../../../../helpers/sidebar/setSidebar";
import { resetSidebar } from "../../../../helpers/sidebar/resetSidebar";

export default function RmStatistics({ pendingRev, approvedCaseRevenue }) {
    const reduxData = useSelector((state) => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const calculateAdvisoryFee = (advisoryFee, banks, status) => {
        if (status === "Completed") {
            if (advisoryFee?.type === "fixed") {
                return parseInt(advisoryFee?.amount);
            } else if (advisoryFee?.type === "percentage") {
                let total = 0;
                banks?.forEach((b) => (total += parseInt(b.cashOut)));
                return (total * parseFloat(advisoryFee?.amount)) / 100;
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    };

    const calculateCashOut = (arr) => {
        let total = 0;
        arr.forEach((a) => {
            a?.institutes?.forEach((i) => {
                if (i?.fundsDisbursed && i?.cashOut) {
                    total += parseInt(i?.cashOut);
                }
            });
        });
        return `${formatCurrency(total, true)}`;
    };

    const statisticsList = [
        {
            statisticsIcon: blueForm,
            statisticsCount:
                reduxData?.cases?.value?.filter((e) => e.status === "Pending")
                    ?.length || 0,
            statisticsTitle: "Total Pending Case",
            url: "/rm-lead/pending-cases",
        },
        {
            statisticsIcon: blueForm,
            statisticsCount: formatCurrency(
                reduxData?.cases?.value
                    ?.filter((e) => e.status === "Pending")
                    ?.reduce(
                        (total, cs) => total + parseInt(cs?.loanAmount || "0"),
                        0
                    ),
                true
            ),
            statisticsTitle: "Total Pending Case Loan Amount",
            url: "/rm-lead/pending-cases",
            loanDetails: true,
        },
        {
            statisticsIcon: dollarIcon,
            statisticsCount:
                reduxData?.cases?.value?.filter((e) => e.status === "Submitted")
                    ?.length || 0,

            statisticsTitle: "Total Submitted Case",
            url: "/rm-lead/submitted-cases",
        },
        {
            statisticsIcon: dollarIcon,
            statisticsCount: formatCurrency(
                reduxData?.cases?.value
                    ?.filter((e) => e.status === "Submitted")
                    ?.reduce(
                        (total, cs) => total + parseInt(cs?.loanAmount || "0"),
                        0
                    ),
                true
            ),
            statisticsTitle: "Total Submitted Case Loan Amount",
            url: "/rm-lead/submitted-cases",
            loanDetails: true,
        },
        {
            statisticsIcon: dollarIcon,
            statisticsCount:
                reduxData?.cases?.value?.filter(
                    (e) => e.status === "Processing"
                )?.length || 0,

            statisticsTitle: "Total Processing Case",
            url: "/rm-lead/processing-cases",
        },
        {
            statisticsIcon: dollarIcon,
            statisticsCount: formatCurrency(
                reduxData?.cases?.value
                    ?.filter((e) => e.status === "Processing")
                    ?.reduce(
                        (total, cs) => total + parseInt(cs?.loanAmount || "0"),
                        0
                    ),
                true
            ),
            statisticsTitle: "Total Processing Case Loan Amount",
            url: "/rm-lead/processing-cases",
            loanDetails: true,
        },
        {
            statisticsIcon: dollarIcon,
            statisticsCount:
                reduxData?.cases?.value?.filter((e) => e.status === "Approved")
                    ?.length || 0,

            statisticsTitle: "Total Approved Case (STA + LO Stage)",
            url: "/rm-lead/approved-cases",
        },
        {
            statisticsIcon: dollarIcon,
            statisticsCount: formatCurrency(
                reduxData?.cases?.value
                    ?.filter((e) => e.status === "Approved")
                    ?.reduce(
                        (total, cs) => total + parseInt(cs?.loanAmount || "0"),
                        0
                    ),
                true
            ),
            statisticsTitle: "Total Approved Case (STA + LO Stage) Loan Amount",
            url: "/rm-lead/approved-cases",
            loanDetails: true,
        },
        {
            statisticsIcon: dollarIcon,
            statisticsCount: calculateCashOut(
                reduxData?.cases?.value?.filter((e) =>
                    e?.institutes?.find((i) => i.fundsDisbursed)
                )
            ),
            statisticsTitle: "Total Loan Disbursement",
        },
        {
            statisticsIcon: dollarIcon,
            statisticsCount: formatCurrency(
                reduxData?.cases?.value?.reduce(
                    (total, cs) =>
                        total +
                        calculateAdvisoryFee(
                            cs?.advisoryFee,
                            cs?.institutes?.filter((e) => e.cashOut),
                            cs?.status
                        ),
                    0
                ),
                true
            ),
            statisticsTitle: "Total Advisory Fee",
        },
        {
            statisticsIcon: dollarIcon,
            statisticsCount:
                reduxData?.cases?.value?.filter((e) => e.status === "Completed")
                    ?.length || 0,

            statisticsTitle: "Total Completed Case",
            url: "/rm-lead/completed-cases",
        },
        {
            statisticsIcon: dollarIcon,
            statisticsCount: formatCurrency(
                reduxData?.cases?.value
                    ?.filter((e) => e.status === "Completed")
                    ?.reduce(
                        (total, cs) => total + parseInt(cs?.loanAmount || "0"),
                        0
                    ),
                true
            ),
            statisticsTitle: "Total Completed Case Loan Amount",
            url: "/rm-lead/completed-cases",
            loanDetails: true,
        },
    ];

    const hideArrows = (title) => {
        if (
            title === "Total Advisory Fee" ||
            title === "Total Loan Disbursement"
        ) {
            return " no-arrow";
        }
    };

    let noHover = (title) => {
        return {
            boxShadow:
                (title === "Total Advisory Fee" ||
                    title === "Total Loan Disbursement") &&
                "none",
        };
    };

    return (
        <div className="statistics-wrap">
            <div className="statistics-wrapper flex column-direction">
                <ul className="list-style-none flex flex-wrap">
                    {statisticsList.map(
                        (
                            {
                                statisticsIcon,
                                statisticsCount,
                                statisticsTitle,
                                url,
                                loanDetails,
                            },
                            index
                        ) => {
                            return (
                                <li
                                    key={`statistics-${index}`}
                                    onClick={() => {
                                        if (
                                            statisticsTitle ===
                                                "Total Pending Case" ||
                                            statisticsTitle ===
                                                "Total Pending Case Loan Amount"
                                        ) {
                                            dispatch(setRoute("Cases"));
                                            resetSidebar();
                                            setSidebar("Pending");
                                        } else if (
                                            statisticsTitle ===
                                                "Total Submitted Case" ||
                                            statisticsTitle ===
                                                "Total Submitted Case Loan Amount"
                                        ) {
                                            dispatch(setRoute("Cases"));
                                            resetSidebar();
                                            setSidebar("Submitted");
                                        } else if (
                                            statisticsTitle ===
                                                "Total Processing Case" ||
                                            statisticsTitle ===
                                                "Total Processing Case Loan Amount"
                                        ) {
                                            dispatch(setRoute("Cases"));
                                            resetSidebar();
                                            setSidebar("Processing");
                                        } else if (
                                            statisticsTitle ===
                                                "Total Approved Case (STA + LO Stage)" ||
                                            statisticsTitle ===
                                                "Total Approved Case (STA + LO Stage) Loan Amount"
                                        ) {
                                            dispatch(setRoute("Cases"));
                                            resetSidebar();
                                            setSidebar("Approved");
                                        } else if (
                                            statisticsTitle ===
                                                "Total Completed Case" ||
                                            statisticsTitle ===
                                                "Total Completed Case Loan Amount"
                                        ) {
                                            dispatch(setRoute("Cases"));
                                            resetSidebar();
                                            setSidebar("Completed");
                                        }

                                        if (loanDetails) {
                                            navigate(url, {
                                                state: {
                                                    loanDetails: true,
                                                    isPrevious: true,
                                                },
                                            });
                                        } else {
                                            navigate(url, {
                                                state: {
                                                    isPrevious: true,
                                                },
                                            });
                                        }
                                    }}
                                >
                                    <Link
                                        to="#"
                                        className={`flex items-center ${hideArrows(
                                            statisticsTitle
                                        )}`}
                                        style={noHover(statisticsTitle)}
                                    >
                                        <section>
                                            <h2>{statisticsCount}</h2>
                                            <span>
                                                {statisticsTitle}{" "}
                                                {/* <small className="low">
                                                    <img
                                                        src={lowIcon}
                                                        alt="lowIcon"
                                                    />{" "}
                                                    +2
                                                </small> */}
                                            </span>
                                        </section>
                                        <figure>
                                            <img
                                                src={statisticsIcon}
                                                alt="statisticsIcon"
                                            />
                                        </figure>
                                    </Link>
                                </li>
                            );
                        }
                    )}
                </ul>
            </div>
            {/* <div className="statistics-wrapper side-wrapper flex column-direction">
                <ul className="list-style-none flex flex-wrap">
                    <li>
                        {activeTab === "tab1" ? (
                            <Link to="#" className="flex items-center">
                                <section>
                                    <h2>{"$ " + totalLoanAmount}</h2>
                                    <span>Total Loan Amount </span>
                                </section>
                                <figure>
                                    <img
                                        src={dollarIcon}
                                        alt="statisticsIcon"
                                    />
                                </figure>
                            </Link>
                        ) : (
                            <Link to="#" className="flex items-center">
                                <section>
                                    <h2>{ownRev}</h2>
                                    <span>Total Loan Amount </span>
                                </section>
                                <figure>
                                    <img
                                        src={dollarIcon}
                                        alt="statisticsIcon"
                                    />
                                </figure>
                            </Link>
                        )}
                        <div className="statistics-tabs">
                            <span
                                onClick={() => {
                                    setActiveTab("tab1");
                                }}
                                className={`${
                                    activeTab === "tab1" ? "active" : ""
                                }`}
                            >
                                All Teams
                            </span>
                            <span
                                onClick={() => {
                                    setActiveTab("tab2");
                                }}
                                className={`${
                                    activeTab === "tab2" ? "active" : ""
                                }`}
                            >
                                Own
                            </span>
                        </div>
                    </li>
                </ul>
            </div> */}
        </div>
    );
}
