import { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import add from "../../../../assets/images/add-icon.svg";
import { UNAVAILABLE_COUNTRIES } from "../../../../utils/constants";
import { Country, State, City } from "country-state-city";
import AddressMultipleFields from "../../../admin/users/edit-user-details/AddressMultipleFields";
import addIcon from "../../../../assets/images/add-icon.svg";
import { missingPopup } from "../../../../helpers/dataHelper/missingData";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../../helpers/toast/toastify";
import { addIndividualEntity } from "../../../../redux/individualEntities/iEntitySlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import { setLoading } from "../../../../redux/loader/loadingSlice";

export default function AddIndividualEntity() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const [fName, setFName] = useState("");
    const [lName, setLName] = useState("");
    const [contact, setContact] = useState("");
    const [email, setEmail] = useState("");
    const [nric, setNric] = useState("");
    const [gender, setGender] = useState("");
    const [dob, setDob] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [city, setCity] = useState("");
    const [region, setRegion] = useState("");
    const [country, setCountry] = useState({
        isoCode: "SG",
        name: "Singapore",
    });
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [inputFields, setInputFields] = useState([]);
    const [customerInterests, setCustomerInterests] = useState([]);
    const [address, setAddress] = useState("");
    const reduxData = useSelector((state) => state);
    const numberRegex = new RegExp(`^[0-9]*$`);
    const alphaNumericRegex = new RegExp(`^[0-9a-zA-Z]*$`);

    useEffect(() => {
        setCountries(
            Country.getAllCountries()
                ?.filter((e) => !UNAVAILABLE_COUNTRIES.includes(e.isoCode))
                ?.map((e) => {
                    return { isoCode: e.isoCode, name: e.name };
                })
        );
    }, []);

    useEffect(() => {
        if (country) {
            setStates(State.getStatesOfCountry(country?.isoCode));
        }
    }, [country]);

    useEffect(() => {
        if (region && country) {
            let nonFiltered = City.getCitiesOfState(
                country?.isoCode,
                region?.isoCode
            );

            const uniqueNames = nonFiltered.reduce((acc, obj) => {
                if (!acc[obj.name]) {
                    acc[obj.name] = true;
                }
                return acc;
            }, {});

            const filteredArray = nonFiltered.filter((obj) => {
                if (uniqueNames[obj.name]) {
                    uniqueNames[obj.name] = false;
                    return true;
                }
                return false;
            });

            setCities(filteredArray);
        }
    }, [region]);

    const getProducts = (pr) => {
        let list = reduxData?.productForms?.value;
        return list?.find((e) => e._id === pr);
    };

    useEffect(() => {
        if (state?.entity) {
            let data = state?.entity;
            setFName(data?.firstName);
            setLName(data?.lastName);
            setEmail(data?.email);
            setNric(data?.nric);
            setGender(data?.gender);
            setDob(data?.dob ? new Date(data?.dob) : null);
            setContact(data?.contact);
            setCountryStateCity(data);
            setAddress(
                data?.addresses && data?.addresses?.length
                    ? data?.addresses[0]
                    : []
            );
            setCustomerInterests(
                data?.customerInterests?.map((e) => {
                    return getProducts(e);
                })
            );
            setInputFields(getInputFields(data?.addresses));
            setPostalCode(data?.postalCode);
        }
    }, [state]);

    const setCountryStateCity = (data) => {
        let allCount = Country.getAllCountries();
        let selectedCount = allCount.find((e) => e.name === data.country);
        setCountry(selectedCount);

        let allCities = City.getCitiesOfCountry(selectedCount?.isoCode);
        let selectedCity = allCities.find((e) => e.name === data.city);
        setCity(selectedCity);

        let allRegions = State.getStatesOfCountry(selectedCount?.isoCode);
        let selectedRegion = allRegions.find((e) => e.name === data.state);
        setRegion(selectedRegion);
    };

    const getInputFields = (data) => {
        if (data?.length > 1) {
            let newData = data.filter((e, i) => i > 0);
            return newData;
        } else {
            return [];
        }
    };

    const submit = async () => {
        if (!fName) missingPopup("First Name");
        else if (!contact) missingPopup("Contact");
        else if (!email) missingPopup("Email");
        else if (!nric) missingPopup("NRIC");
        else {
            if (inputFields?.length) {
                var allAddresses = inputFields?.filter((e) => e !== "");
                allAddresses?.unshift(address);
            }
            let data = {
                firstName: fName,
                lastName: lName,
                contact,
                email,
                nric,
                gender,
                postalCode,
                city: city?.name,
                country: country?.name,
                state: region?.name,
                addresses: inputFields?.length ? allAddresses : [address],
                customerInterests: customerInterests?.map((e) => e._id),
            };
            if (dob) {
                data = { ...data, dob };
            } else {
                data = { ...data, dob: null };
            }

            if (state?.entity) {
                dispatch(setLoading(true));

                const response = await api("post", "individualEntity/update", {
                    ...data,
                    id: state?.entity?._id,
                });
                dispatch(setLoading(false));

                if (response?.data?.status) {
                    toastify(
                        "Individual Entity Updated Successfully",
                        "success"
                    );

                    if (state?.redirectData) {
                        navigate("/add-leads", {
                            state: {
                                redirectData: {
                                    ...state?.redirectData,
                                    entity: response?.data?.data,
                                },
                            },
                        });
                    } else if (state?.navigate_lead) {
                        navigate("/add-leads", {
                            state: {
                                ...state,
                                entity: response?.data?.data,
                            },
                        });
                    } else {
                        navigate("/entities");
                    }
                } else {
                    toastify(response?.message);
                }
            } else {
                dispatch(setLoading(true));

                const response = await api(
                    "post",
                    "individualEntity/create",
                    data
                );
                dispatch(setLoading(false));

                if (response?.data?.status) {
                    toastify(
                        "Individual Entity Created Successfully",
                        "success"
                    );
                    if (state?.navigate) {
                        dispatch(addIndividualEntity(response?.data?.data));

                        navigate("/business-entity", {
                            state: { ...state, user: response?.data?.data },
                        });
                    } else if (state?.navigate_lead) {
                        dispatch(addIndividualEntity(response?.data?.data));

                        navigate("/add-leads", {
                            state: {
                                ...state,
                                entity: response?.data?.data,
                            },
                        });
                    } else {
                        navigate("/entities");
                    }
                } else {
                    toastify(response?.message);
                }
            }
        }
    };

    const handleSelect = (selectedList) => {
        setCustomerInterests(selectedList);
    };

    const handleRemove = (selectedList) => {
        setCustomerInterests(selectedList);
    };

    return (
        <>
            <h2 className="flex flex-wrap mini-title">
                <span
                    onClick={() =>
                        navigate(
                            "/" +
                                window.location.pathname.split("/")[1] +
                                "/entities"
                        )
                    }
                >
                    Entity /
                </span>{" "}
                {state?.entity ? "Edit " : "Add "} Individual Entity
            </h2>
            <div className="card">
                <div className="card-title">
                    <h2>Individual Entity</h2>
                </div>
                <div className="card-body">
                    <div className="edit-form-wrapper">
                        <ul className="list-style-none flex flex-wrap">
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">First Name *</label>
                                    <input
                                        type="text"
                                        placeholder="Enter first name"
                                        className="form-control mandatory"
                                        value={fName}
                                        onChange={(e) =>
                                            setFName(e.target.value)
                                        }
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Last Name</label>
                                    <input
                                        type="text"
                                        placeholder="Enter last name"
                                        className="form-control"
                                        value={lName}
                                        onChange={(e) =>
                                            setLName(e.target.value)
                                        }
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Contact Number *</label>
                                    <input
                                        type="text"
                                        placeholder="Contact number "
                                        className="form-control mandatory"
                                        value={contact}
                                        maxLength={15}
                                        onChange={(e) => {
                                            if (
                                                numberRegex.test(
                                                    e.target.value
                                                ) ||
                                                e.target.value === ""
                                            ) {
                                                setContact(e.target.value);
                                            }
                                        }}
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Email *</label>
                                    <input
                                        type="text"
                                        placeholder="Email"
                                        className="form-control mandatory"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">
                                        NRIC (Last 4 Characters) *
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="NRIC"
                                        className="form-control mandatory"
                                        value={nric}
                                        maxLength={4}
                                        onChange={(e) => {
                                            if (
                                                alphaNumericRegex.test(
                                                    e.target.value
                                                ) ||
                                                e.target.value === ""
                                            ) {
                                                setNric(
                                                    e.target.value.toUpperCase()
                                                );
                                            }
                                        }}
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Gender</label>
                                    <select
                                        className="form-control"
                                        value={gender}
                                        onChange={(e) =>
                                            setGender(e.target.value)
                                        }
                                    >
                                        <option value="">Select</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Date of birth</label>
                                    <DatePicker
                                        format="dd/MM/y"
                                        className="form-control"
                                        onChange={(e) => {
                                            setDob(e);
                                        }}
                                        value={dob}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Customer Interests</label>
                                    <Multiselect
                                        isObject={true}
                                        showCheckbox
                                        options={reduxData?.productForms?.value}
                                        selectedValues={customerInterests}
                                        onSelect={handleSelect}
                                        onRemove={handleRemove}
                                        displayValue="name"
                                        className="dropdown-container"
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Country</label>
                                    <select
                                        value={JSON.stringify(country)}
                                        className="form-control"
                                        onChange={(e) => {
                                            if (e.target.value === "") {
                                                setCountry();
                                                setCity();
                                                setRegion();
                                            } else {
                                                setCountry(
                                                    JSON.parse(e.target.value)
                                                );
                                            }
                                        }}
                                    >
                                        <option
                                            value=""
                                            label={"Select a Country"}
                                        >
                                            Select a Country
                                        </option>
                                        {countries?.map((e) => (
                                            <option
                                                value={JSON.stringify(e)}
                                                label={e.name}
                                            >
                                                {e.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">State/Region</label>
                                    <select
                                        className="form-control"
                                        value={JSON.stringify(region)}
                                        onChange={(e) => {
                                            if (e.target.value === "") {
                                                setRegion();
                                            } else {
                                                setRegion(
                                                    JSON.parse(e.target.value)
                                                );
                                            }
                                        }}
                                        disabled={country?.isoCode === "SG"}
                                    >
                                        <option
                                            value=""
                                            label={"Select a State"}
                                        >
                                            Select a State
                                        </option>
                                        {states?.map((e) => (
                                            <option
                                                value={JSON.stringify(e)}
                                                label={e.name}
                                            >
                                                {e.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">City</label>
                                    <select
                                        className="form-control"
                                        value={JSON.stringify(city)}
                                        onChange={(e) => {
                                            if (e.target.value === "") {
                                                setCity();
                                            } else {
                                                setCity(
                                                    JSON.parse(e.target.value)
                                                );
                                            }
                                        }}
                                        disabled={country?.isoCode === "SG"}
                                    >
                                        <option
                                            value=""
                                            label={"Select a City"}
                                        >
                                            Select a City
                                        </option>
                                        {cities?.map((e) => (
                                            <option
                                                value={JSON.stringify(e)}
                                                label={e.name}
                                            >
                                                {e.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <div className="flex">
                                        <label htmlFor="" className="flex">
                                            Address 1
                                            {inputFields.length === 0 && (
                                                <button
                                                    className="add-address-btn"
                                                    onClick={() => {
                                                        if (
                                                            inputFields[
                                                                inputFields.length -
                                                                    1
                                                            ] !== "" &&
                                                            address !== ""
                                                        ) {
                                                            setInputFields([
                                                                ...inputFields,
                                                                "",
                                                            ]);
                                                        }
                                                    }}
                                                >
                                                    <img
                                                        src={addIcon}
                                                        alt="addIcon"
                                                    />
                                                </button>
                                            )}
                                        </label>
                                    </div>
                                    <input
                                        type="text"
                                        value={address}
                                        onChange={(e) =>
                                            setAddress(e.target.value)
                                        }
                                        className="form-control"
                                        placeholder="Address 1"
                                    />
                                </div>
                            </li>
                            {inputFields?.length > 0 && (
                                <li className="w-100">
                                    <AddressMultipleFields
                                        inputFields={inputFields}
                                        setInputFields={setInputFields}
                                        address={address}
                                    />
                                </li>
                            )}
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Postal Code</label>
                                    <input
                                        type="text"
                                        placeholder="Postal Code"
                                        className="form-control"
                                        value={postalCode}
                                        onChange={(e) =>
                                            // setPostalCode(e.target.value)
                                            {
                                                if (
                                                    numberRegex.test(
                                                        e.target.value
                                                    ) ||
                                                    e.target.value === ""
                                                ) {
                                                    setPostalCode(
                                                        e.target.value
                                                    );
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </li>

                            <li className="flex w-100">
                                <button
                                    className="simple-btn cancel"
                                    onClick={() => {
                                        if (state?.redirectData) {
                                            navigate(-1, {
                                                state: state?.redirectData,
                                            });
                                        } else {
                                            if (state?.navigate) {
                                                navigate("/business-entity", {
                                                    state: { ...state },
                                                });
                                            } else if (state?.navigate_lead) {
                                                navigate("/add-leads", {
                                                    state: { ...state },
                                                });
                                            } else {
                                                navigate("/entities");
                                            }
                                        }
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="simple-btn submit"
                                    onClick={submit}
                                >
                                    Submit
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
