import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from "react-accessible-accordion";
import React, { useEffect, useState } from "react";
import caseIcon from "../../../../assets/images/caseIcon.svg";
import fileIcon from "../../../../assets/images/file-icon.svg";
import { useNavigate } from "react-router-dom";
import { api } from "../../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../../helpers/toast/toastify";
import { DocumentModal } from "../../../../components/modals/DocumentModal";
import { useSelector } from "react-redux";
import { SendDocumentModal } from "../../../../components/modals/SendDocumentModal";
import { AddCase } from "../../../../components/modals/AddCase";
import { setLoading } from "../../../../redux/loader/loadingSlice";
import { useDispatch } from "react-redux";
import { storeLeads } from "../../../../redux/leads/leadsSlice";
import { PdfModal } from "../../../../components/modals/PdfModal";
import { ImageModal } from "../../../../components/modals/ImageModal";
import axios from "axios";
import { getCaseUsers } from "../../../../helpers/dataHelper/getCaseUsers";
import {
    formatDate,
    shortFormatDate,
} from "../../../../helpers/dataHelper/dateFormatter";
import { EmailsModal } from "../../../../components/modals/EmailsModal";
import { DownloadDocsModal } from "../../../../components/modals/DownloadDocsModal";
import parse from "html-react-parser";
import DatePicker from "react-date-picker";
import { formatCurrency } from "../../../../helpers/dataHelper/currentFormatter";
import { AbortionModal } from "../../../../components/modals/AbortionModal";
import { AlertModal } from "../../../../components/modals/AlertModal";

export default function CaseInformation(props) {
    const [selectedCase, setSelectedCase] = useState();
    const [emailToSend, setEmailToSend] = useState([]);
    const [showEmails, setShowEmails] = useState(false);
    const [show, setShow] = useState(false);
    const [pdfShow, setPdfShow] = useState(false);
    const [downloadShow, setDownloadShow] = useState(false);
    const [imageShow, setImageShow] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [showSend, setShowSend] = useState(false);
    const [url, setUrl] = useState("");
    const [addModal, setAddModal] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const reduxData = useSelector((state) => state);
    const [elements, setElements] = useState([]);
    const [allApprovedAmounts, setAllApprovedAmounts] = useState([]);
    const [allSubmittedAmounts, setAllSubmittedAmounts] = useState([]);
    const [abortionModal, setAbortionModal] = useState(false);
    const [completeModal, setCompleteModal] = useState(false);

    useEffect(() => {
        if (selectedCase?.institutes.length) {
            let aArr = [];
            selectedCase?.institutes.forEach((i) =>
                aArr.push(i.approvedAmount || "0")
            );
            setAllApprovedAmounts(aArr);

            let sArr = [];
            selectedCase?.institutes.forEach((i) => sArr.push(i.amount || "0"));
            setAllSubmittedAmounts(sArr);
        }
    }, [selectedCase?.institutes]);

    const updateData = async () => {
        props.setModalClick(false);
        await props?.getCases();
    };

    useEffect(() => {
        if (props.case) {
            setSelectedCase(props.case);
        }
    }, [props.case]);

    const showDoc = async (caseDoc) => {
        setUrl(caseDoc?.url);
        let extension = caseDoc?.url.split(".");
        if (extension[extension.length - 1] === "pdf") {
            const response = await axios({
                url: caseDoc?.url,
                method: "GET",
                responseType: "blob", // important
            });

            const blob = new Blob([response.data], { type: "application/pdf" });
            const objectUrl = URL.createObjectURL(blob);
            window.open(objectUrl, "_blank");
        } else {
            setImageShow(true);
        }
    };

    const getCaseDocument = (doc) => {
        let _caseDoc = (
            selectedCase ? selectedCase : props?.case
        )?.caseDocuments.find((e) => e.documentId === doc?._id);

        if (_caseDoc && _caseDoc?.url) {
            return (
                <>
                    {_caseDoc?.input && (
                        <div className="input-font">{_caseDoc?.input}</div>
                    )}

                    <span
                        onClick={async () => {
                            if (_caseDoc?.uuid) {
                                const response = await api(
                                    "post",
                                    "cases/getSignedDocument",
                                    { uuid: _caseDoc?.uuid }
                                );

                                if (
                                    !response?.data?.status ||
                                    !response?.data?.data
                                ) {
                                    showDoc(_caseDoc);
                                } else {
                                    window.open(response?.data?.data, "_blank");
                                }
                            } else {
                                showDoc(_caseDoc);
                            }
                        }}
                        className="cursor-pointer"
                    >
                        <img src={fileIcon} alt="pdfIcon" /> {_caseDoc?.name}
                    </span>
                </>
            );
        }
        if (_caseDoc && _caseDoc?.input) {
            return <div className="input-font">{_caseDoc?.input}</div>;
        }
    };

    const waiting = async (isDraft) => {
        if (props.lead) {
            dispatch(setLoading(true));

            let response = await api("post", "leads/update", {
                id: props.lead?._id,
                status: "Pending",
            });

            if (response?.data?.status) {
                let allUsers = getCaseUsers(response?.data?.data);
                if (!allUsers.includes(reduxData?.auth?.user?._id)) {
                    allUsers.push(reduxData?.auth?.user?._id);
                }
                await api("post", "activity/create", {
                    activity: `Case ${response?.data?.data?.identifier} status has been changed to Waiting for Customer.`,
                    users: allUsers,
                });
                await sendNotification(
                    "Case status changed to Waiting for Customer",
                    "Case Status"
                );

                await getLeads();
                toastify("Lead status changed to Pending", "success");
                dispatch(setLoading(false));

                props.setModalClick(false);
            } else {
                toastify("Error changing status", "error");
            }
            dispatch(setLoading(false));
        } else {
            dispatch(setLoading(true));

            let response = await api("post", "cases/update", {
                id: selectedCase?._id,
                status:
                    selectedCase?.status === "Pending" ? "Draft" : "Pending",
                logs: [
                    ...selectedCase?.logs,
                    {
                        date: new Date(),
                        operator: reduxData?.auth?.user?._id,
                        comment: `Case has been set to waiting for client.`,
                    },
                ],
            });

            if (response?.data?.status) {
                let allUsers = getCaseUsers(response?.data?.data);
                if (!allUsers.includes(reduxData?.auth?.user?._id)) {
                    allUsers.push(reduxData?.auth?.user?._id);
                }
                await api("post", "activity/create", {
                    activity: `${response?.data?.data?.identifier} - ${
                        isDraft
                            ? "Case has been set to Draft"
                            : "Case has been set to waiting for client"
                    }.`,
                    users: allUsers,
                });
                await sendNotification(
                    "Case status changed to Waiting for Customer",
                    "Case Status"
                );

                await props?.getCases();
                if (isDraft) {
                    toastify("Case status changed to Draft", "success");
                } else {
                    toastify("Case status changed to Pending", "success");
                }
                dispatch(setLoading(false));
                props?.setOpenDetailModal(false);
            } else {
                toastify("Error changing status", "error");
            }
            dispatch(setLoading(false));
        }
    };

    const submission = async () => {
        // if (props.lead) {
        //     let response = await api("post", "leads/update", {
        //         id: props.lead?._id,
        //         status: "Submitted",
        //     });

        //     if (response?.data?.status) {
        //         toastify("Lead status changed to Submitted", "success");
        //         await getLeads();
        //         await props?.getCases();
        //         props.setModalClick(false);
        //     } else {
        //         toastify("Error changing status", "error");
        //     }
        // } else {
        dispatch(setLoading(true));
        let response = await api("post", "cases/update", {
            id: selectedCase?._id,
            status: "Submitted",
            decision: "",
            logs: [
                ...selectedCase?.logs,
                {
                    date: new Date(),
                    operator: reduxData?.auth?.user?._id,
                    comment: `Case has been submitted to the Case Manager.`,
                },
            ],
        });
        dispatch(setLoading(false));

        if (response?.data?.status) {
            let allUsers = getCaseUsers(response?.data?.data);
            if (!allUsers.includes(reduxData?.auth?.user?._id)) {
                allUsers.push(reduxData?.auth?.user?._id);
            }
            await api("post", "activity/create", {
                activity: `${response?.data?.data?.identifier} - Case has been submitted to the Case Manager.`,
                users: allUsers,
            });
            await sendNotification(
                `Case ${selectedCase?.identifier} submitted to Case Manager`,
                "Case submitted",
                true
            );

            toastify("Case submitted to the Case Manager", "success");
            await props?.getCases();
            await getLeads();
            props?.setOpenDetailModal(false);
        } else {
            toastify("Error changing status", "error");
        }
        // }
    };

    const handleSentToCustomer = () => {
        setShow(true);
        // setShowSend(true);
    };

    const addCase = async (params) => {
        dispatch(setLoading(true));

        const response = await api("post", "cases/update", {
            id: selectedCase?._id,
            ...params,
        });
        dispatch(setLoading(false));

        if (response?.data?.status) {
            setAddModal(false);
            props?.setModalClick(false);
            navigate("/rm-lead/edit-case", {
                state: {
                    case: { ...response?.data?.data },
                },
            });
            toastify("Case Modified Successfully", "success");
            await getLeads();
        } else {
            toastify(response?.message, "error");
        }
    };

    const getLeads = async () => {
        let response = await api("post", "leads/index", {
            assignedFromRole: reduxData?.userRoles?.currentRole,
        });

        if (response?.status) {
            dispatch(storeLeads(response?.data?.data?.reverse()));
        } else {
            toastify(response?.message, "error");
        }
    };

    const getClassName = (doc) => {
        let thisCase = selectedCase || props.case;
        let myDoc = thisCase?.caseDocuments?.find(
            (e) => e.documentId === doc._id
        );
        if (myDoc) {
            if ((doc?.upload && !myDoc?.url) || (doc?.input && !myDoc?.input)) {
                if (myDoc?.pdpaDeleted) {
                    return "";
                }
                return "reject";
            } else {
                return "";
            }
        } else {
            return "waiting";
        }
    };

    const getPdpa = (doc) => {
        let thisCase = selectedCase || props.case;
        let myDoc = thisCase?.caseDocuments?.find(
            (e) => e.documentId === doc._id
        );
        if (myDoc?.pdpaDeleted) {
            return true;
        }
        return false;
    };

    const confirm = async () => {
        setShow(false);
        setShowSend(true);
    };

    const handleSendDoc = async () => {
        dispatch(setLoading(true));

        if (!reduxData?.auth?.user?.email)
            toastify(
                "You do not have a valid email address to send case documents.",
                "error"
            );
        else {
            let data = {
                urls: documents?.map((e) => e.url),
                caseId: (selectedCase || props?.case)?._id,
                userId: reduxData?.auth?.user?._id,
                sender: reduxData?.auth?.user?.email,
                receiver: emailToSend,
            };
            const response = await api("post", "cases/signDocuments", data);

            if (response?.data?.status) {
                let allUsers = getCaseUsers(response?.data?.data);
                if (!allUsers.includes(reduxData?.auth?.user?._id)) {
                    allUsers.push(reduxData?.auth?.user?._id);
                }
                await api("post", "activity/create", {
                    activity: `Case ${response?.data?.data?.identifier} document have been sent for signing.`,
                    users: allUsers,
                });
                await sendNotification(
                    `Documents have been sent for signing`,
                    'Document sent to Customer"'
                );

                setSelectedCase(response?.data?.data);
                props?.getCases();
                toastify("Sign Request sent successfully.", "success");
                setShow(false);
            } else {
                toastify(response?.message, "error");
            }
        }
        dispatch(setLoading(false));
    };

    const sendNotification = async (message, title, isAll) => {
        let participants = [];
        if (isAll) {
            reduxData?.users?.value?.forEach((element) => {
                if (element.roles.includes("Case Manager")) {
                    if (element._id) {
                        participants.push(element._id);
                    }
                }
            });
        }
        if (selectedCase?.lead?.assignedTo?._id) {
            participants.push(selectedCase?.lead?.assignedTo?._id);
        }
        if (selectedCase?.lead?.assignedFrom?._id) {
            participants.push(selectedCase?.lead?.assignedFrom?._id);
        }

        let _participants = [...new Set(participants)];

        participants = _participants?.map((e) => {
            return { user: e, acknowledged: false };
        });

        let data = {
            participants,
            remarks: message,
            title: title,
        };

        await api("post", "notifications/create", data);
    };

    const markComplete = async () => {
        let response = await api("post", "cases/update", {
            id: props?.case?._id,
            rmPdpa: true,
            logs: [
                ...props?.case?.logs,
                {
                    date: new Date(),
                    operator: reduxData?.auth?.user?._id,
                    comment: `Case has been marked as RM PDPA Delete.`,
                },
            ],
        });

        if (response?.data?.status) {
            let allUsers = getCaseUsers(props?.case);
            if (!allUsers.includes(reduxData?.auth?.user?._id)) {
                allUsers.push(reduxData?.auth?.user?._id);
            }
            await api("post", "activity/create", {
                activity: `${response?.data?.data?.identifier} - Case has been marked as RM PDPA Delete.`,
                users: allUsers,
            });
            await sendNotification(
                `Case ${selectedCase?.identifier} has been marked as RM Pdpa Completed.`,
                "RM PDPA complete",
                true
            );
            toastify("Case marked as PDPA Complete", "success");
            await props?.getCases();
            props?.setOpenDetailModal(false);
        } else {
            toastify("Error marking case as PDPA Complete!", "error");
        }
    };

    useEffect(() => {
        if (props?.lead?.cases?.length) {
            setSelectedCase(props?.lead?.cases[0]);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const els = await Promise.all(
                    selectedCase?.product?.internalForms?.map(getStatus)
                );
                setElements(els);
            } catch (error) {
                console.error(error);
            }
        };

        if (selectedCase) {
            fetchData();
        }
    }, [selectedCase]);

    const getStatus = async (e) => {
        // return <h4 className={`cursor-pointer reject`}>{e?.name}</h4>;
        const response = await api("post", "forms/formStatus", {
            caseId: selectedCase?._id,
            formId: e._id,
        });
        if (response?.data?.data === "incomplete") {
            return <h4 className={`cursor-pointer reject`}>{e?.name}</h4>;
        } else {
            return <h4 className={`cursor-pointer`}>{e?.name}</h4>;
        }
    };

    const downloadDocs = async (data, email) => {
        if (data?.length > 0) {
            if (email) {
                try {
                    const emailBody = "Please find the attached files:";

                    const formData = new FormData();

                    const fetchAndAttachFiles = async () => {
                        for (let i = 0; i < data.length; i++) {
                            const dc = data[i];
                            const response = await fetch(dc.url);
                            const blob = await response.blob();
                            const file = new File([blob], dc.name, {
                                type: blob.type,
                            });
                            formData.appendFile(`attachment${i}`, file);
                        }

                        formData.append("body", emailBody);

                        const form = document.createElement("form");
                        form.action = "mailto:" + reduxData?.auth?.user?.email;
                        form.method = "post";
                        form.enctype = "multipart/form-data";
                        form.style.display = "none";

                        for (const [key, value] of formData.entries()) {
                            const input = document.createElement("input");
                            input.type = "hidden";
                            input.name = key;
                            input.value = value;
                            form.appendChild(input);
                        }

                        if (form.checkValidity()) {
                            document.body.appendChild(form);
                            form.submit();
                        }
                    };

                    fetchAndAttachFiles();
                } catch (error) {
                    console.log({ error });
                }
            } else {
                for (const dc of data) {
                    try {
                        const response = await fetch(dc.url);
                        const blob = await response.blob();
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = url;
                        link.download = dc.name;
                        link.click();
                        window.URL.revokeObjectURL(url);
                    } catch (error) {
                        toastify(`Error downloading ${dc.name}:`);
                        console.log(`Error downloading ${dc}:`, error);
                    }
                }
            }
        }
    };

    const abortCase = async () => {
        dispatch(setLoading(true));
        let response = await api("post", "cases/update", {
            id: props?.case?._id,
            status: "Rejected",
            rmPdpa: true,
            logs: [
                ...props?.case?.logs,
                {
                    date: new Date(),
                    operator: reduxData?.auth?.user?._id,
                    comment: `Case has been aborted by RM.`,
                },
            ],
        });

        if (response?.data?.status) {
            let allUsers = getCaseUsers(response?.data?.data);
            if (!allUsers.includes(reduxData?.auth?.user?._id)) {
                allUsers.push(reduxData?.auth?.user?._id);
            }
            await api("post", "activity/create", {
                activity: `${response?.data?.data?.identifier} - Case has been aborted by RM.`,
                users: allUsers,
            });
            await sendNotification(
                `Case ${selectedCase?.identifier} has been aborted by RM.`,
                "Case Aborted.",
                true
            );
            await props?.getCases();
            toastify("Case has been aborted successfully.", "success");
            dispatch(setLoading(false));
            setCompleteModal(false);
            setAbortionModal(true);
            // props?.setOpenDetailModal(false);
        } else {
            toastify("Error marking case as aborted!", "error");
        }
        dispatch(setLoading(false));
    };

    const confirmActionAbortion = () => {
        setAbortionModal(false);
        props?.setOpenDetailModal(false);
    };

    return (
        <div className="case-information">
            {show && (
                <DocumentModal
                    getCases={props?.getCases}
                    case={selectedCase}
                    setShow={setShow}
                    setSelectedCase={setSelectedCase}
                    confirm={() => {
                        setShowEmails(true);
                        setShow(false);
                    }}
                    documents={documents}
                    setDocuments={setDocuments}
                    email={emailToSend}
                    setEmail={setEmailToSend}
                />
            )}
            {completeModal && (
                <AlertModal
                    confirmAction={abortCase}
                    setModalClick={() => setCompleteModal(false)}
                    confirmBtn={true}
                    modalTitle={[
                        <h2>Are you sure you want to Abort this case?</h2>,
                    ]}
                />
            )}
            {downloadShow && (
                <DownloadDocsModal
                    setShow={setDownloadShow}
                    confirmAction={downloadDocs}
                    case={selectedCase}
                />
            )}
            {showEmails && (
                <EmailsModal
                    getCases={props?.getCases}
                    case={selectedCase}
                    setShow={setShow}
                    setSelectedCase={setSelectedCase}
                    confirm={confirm}
                    email={emailToSend}
                    setEmail={setEmailToSend}
                    setModal={setShowEmails}
                />
            )}

            {showSend && (
                <SendDocumentModal
                    confirmAction={() => {
                        setShowSend(false);
                        setShowEmails(false);
                        handleSendDoc();
                        setEmailToSend([]);
                        setDocuments([]);
                    }}
                    setModalClick={() => setShowSend(false)}
                    confirmBtn={true}
                    modalTitle={[
                        <h2>
                            Do you really want to send documents to{" "}
                            {emailToSend?.map((e, i) => {
                                return `${e.email}${
                                    i !== emailToSend.length - 1 ? ", " : ""
                                }`;
                            })}
                            ? <br />
                        </h2>,
                    ]}
                    modalDescription={[]}
                    setDocuments={setDocuments}
                    email={emailToSend}
                />
            )}

            {abortionModal && (
                <AbortionModal confirmAction={confirmActionAbortion} />
            )}

            {addModal && (
                <AddCase
                    setModalClick={() => {
                        setAddModal(!addModal);
                    }}
                    editCase={addCase}
                    case={selectedCase}
                />
            )}

            {pdfShow && (
                <PdfModal
                    url={url}
                    download={() => {
                        setPdfShow(!pdfShow);
                        window.open(url);
                    }}
                    setShow={() => setPdfShow(!pdfShow)}
                />
            )}

            {imageShow && (
                <ImageModal
                    url={url}
                    download={() => {
                        setImageShow(!imageShow);
                        window.open(url);
                    }}
                    setShow={() => setImageShow(!imageShow)}
                />
            )}

            <div className="flex case-dorpdown">
                <select
                    value={JSON.stringify(selectedCase)}
                    onChange={(e) =>
                        setSelectedCase(JSON.parse(e.target.value))
                    }
                    disabled={props?.case}
                >
                    <option value="" style={{ display: "none" }}>
                        {props?.lead
                            ? "Select Case Product"
                            : props?.case?.identifier}
                    </option>
                    {props?.lead?.cases?.map((e) => (
                        <option value={JSON.stringify(e)}>
                            {e?.identifier}
                        </option>
                    ))}
                </select>
            </div>

            <Accordion
                allowZeroExpanded
                allowMultipleExpanded
                preExpanded={["1", "2", "3", "4"]}
            >
                <AccordionItem uuid="1">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Product Specification
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div className="responsive-table case-information-table">
                            <table className="table first-table">
                                <thead>
                                    <tr>
                                        <th>Product Name</th>
                                        <th>Product Specification</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{selectedCase?.product?.name}</td>
                                        <td>
                                            {selectedCase?.product?.description}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Required Documents</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <section className="docs-list">
                                                {selectedCase?.product?.documents?.map(
                                                    (e) => (
                                                        <span>{e?.name}</span>
                                                    )
                                                )}
                                            </section>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Requested Loan Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <section className="docs-list">
                                                <span>
                                                    {formatCurrency(
                                                        selectedCase?.loanAmount,
                                                        true
                                                    ) || "N/A"}
                                                </span>
                                            </section>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Advisory Fee</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="form-group">
                                                <label
                                                    id="advisory"
                                                    className="check-radio f-14"
                                                >
                                                    <input
                                                        type="radio"
                                                        name="advisory"
                                                        checked={
                                                            selectedCase
                                                                ?.advisoryFee
                                                                ?.type ===
                                                            "fixed"
                                                        }
                                                    />
                                                    <span>Fixed</span>
                                                </label>
                                                <label
                                                    id="advisory"
                                                    className="check-radio f-14"
                                                >
                                                    <input
                                                        type="radio"
                                                        name="advisory"
                                                        checked={
                                                            selectedCase
                                                                ?.advisoryFee
                                                                ?.type ===
                                                            "percentage"
                                                        }
                                                    />
                                                    <span>Percentage</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Value"
                                                    className="form-control w-300 f-14"
                                                    value={
                                                        selectedCase
                                                            ?.advisoryFee
                                                            ?.type === "fixed"
                                                            ? formatCurrency(
                                                                  selectedCase
                                                                      ?.advisoryFee
                                                                      ?.amount,
                                                                  true
                                                              )
                                                            : selectedCase
                                                                  ?.advisoryFee
                                                                  ?.amount
                                                    }
                                                    disabled
                                                    maxLength={10}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem uuid="2">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Case Documents
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div className="document-wrapper flex">
                            <div className="cases-doc-list">
                                <ul className="list-style-none">
                                    {/* INTERNAL FORMS */}
                                    <li className="title">
                                        <h2>Internal Form</h2>
                                    </li>
                                    {selectedCase?.product?.internalForms?.map(
                                        (e) => (
                                            <li
                                                className={`bordered-form`}
                                                onClick={async () => {
                                                    if (
                                                        selectedCase?.status !==
                                                            "Completed" &&
                                                        selectedCase?.status !==
                                                            "Submitted" &&
                                                        selectedCase?.status !==
                                                            "Processing"
                                                    ) {
                                                        // window.open(
                                                        //     `${
                                                        //         process.env
                                                        //             .REACT_APP_BASE_URL
                                                        //     }form/${e?._id}/${
                                                        //         (
                                                        //             selectedCase ||
                                                        //             props?.case
                                                        //         )?._id
                                                        //     }`,
                                                        //     "_blank"
                                                        // );
                                                        navigate(
                                                            `/form/${e?._id}/${
                                                                (
                                                                    selectedCase ||
                                                                    props?.case
                                                                )?._id
                                                            }`,
                                                            {
                                                                state: {
                                                                    role: reduxData
                                                                        ?.userRoles
                                                                        ?.currentRole,
                                                                },
                                                            }
                                                        );
                                                    }
                                                }}
                                            >
                                                {elements.find(
                                                    (f) =>
                                                        f?.props?.children ===
                                                        e.name
                                                )}
                                            </li>
                                        )
                                    )}

                                    {/* DOCUMENTS */}
                                    <li className="title">
                                        <h2>Required Documents</h2>
                                    </li>
                                    {selectedCase?.product?.documents?.map(
                                        (e) => (
                                            <li>
                                                <h4 className={getClassName(e)}>
                                                    {e?.name}{" "}
                                                    {getPdpa(e) &&
                                                        " - PDPA Deleted"}
                                                    {e.url && (
                                                        <img
                                                            src={caseIcon}
                                                            alt="caseIcon"
                                                            className="cursor-pointer"
                                                            onClick={() =>
                                                                window.open(
                                                                    e.url,
                                                                    "_blank"
                                                                )
                                                            }
                                                        />
                                                    )}
                                                    <h2 className="ml-auto user-input"></h2>
                                                </h4>
                                                {/* {getInput(e)} */}

                                                {getCaseDocument(e)}
                                                {/* <span>
                                                    <img
                                                        src={pdfIcon}
                                                        alt="pdfIcon"
                                                    />{" "}
                                                    Business Registration.pdf
                                                </span> */}
                                            </li>
                                        )
                                    )}

                                    {/* BANKS */}
                                    <li className="title">
                                        <h2>Financial Institutes</h2>
                                    </li>
                                    {selectedCase?.institutes?.map((e) => (
                                        <li>
                                            <h4
                                                className={
                                                    e.decision === "Rejected"
                                                        ? "reject"
                                                        : !e.decision
                                                        ? "waiting"
                                                        : ""
                                                }
                                            >
                                                {e?.bank?.name}{" "}
                                            </h4>
                                        </li>
                                    ))}
                                </ul>

                                {props?.lead?.cases?.length !== 0 &&
                                    reduxData?.userRoles?.currentRole !==
                                        "Lead Coordinator" &&
                                    selectedCase?.status !== "Completed" &&
                                    selectedCase?.status !== "Submitted" &&
                                    selectedCase?.status !== "Processing" &&
                                    !props?.lead && (
                                        <button
                                            className="simple-btn"
                                            onClick={handleSentToCustomer}
                                        >
                                            Send to Customer
                                        </button>
                                    )}

                                {/* {props?.lead?.cases?.length !== 0 &&
                                    !props?.lead && (
                                        <button
                                            className="simple-btn mx-250 mt-20"
                                            onClick={() =>
                                                setDownloadShow(true)
                                            }
                                        >
                                            Download All
                                        </button>
                                    )} */}
                            </div>

                            <div style={{ width: "50%" }}>
                                {selectedCase &&
                                    selectedCase?.attachments?.length > 0 && (
                                        <div
                                            className="cases-doc-list "
                                            style={{
                                                width: "100%",
                                                padding: 0,
                                            }}
                                        >
                                            <section
                                                className="latest-comment bg-white p-0"
                                                style={{ width: "100%" }}
                                            >
                                                <h3 className="p-0 m-0">
                                                    Attachments
                                                </h3>
                                            </section>
                                            <ul className="p-0 m-0 mb-0">
                                                <div className="flex column-direction case_input_des ">
                                                    {selectedCase?.attachments?.map(
                                                        (e) => {
                                                            return (
                                                                <li className="p-0">
                                                                    <span
                                                                        style={{
                                                                            padding:
                                                                                "20px 40px 20px 20px",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                fileIcon
                                                                            }
                                                                            className="cursor-pointer"
                                                                            alt="pdfIcon"
                                                                            onClick={() => {
                                                                                window.open(
                                                                                    e.url
                                                                                );
                                                                            }}
                                                                        />
                                                                        <div
                                                                            className="file_name cursor-pointer"
                                                                            onClick={() => {
                                                                                window.open(
                                                                                    e.url
                                                                                );
                                                                            }}
                                                                        >
                                                                            {
                                                                                e?.filename
                                                                            }
                                                                        </div>
                                                                    </span>
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </ul>
                                        </div>
                                    )}
                                {(!props?.case
                                    ? selectedCase
                                    : props?.case) && (
                                    <section
                                        className="latest-comment "
                                        style={{ width: "100%" }}
                                    >
                                        <h3>Latest Comment</h3>

                                        <ul className="list-style-none flex column-direction">
                                            {(!props?.case
                                                ? selectedCase
                                                : props?.case
                                            )?.comments.map((data) => {
                                                return (
                                                    <li>
                                                        <h6>
                                                            {data?.commentBy &&
                                                                ` ${
                                                                    data
                                                                        ?.commentBy
                                                                        ?.username ||
                                                                    data?.commentBy
                                                                }`}
                                                        </h6>
                                                        <span>
                                                            {parse(
                                                                data.comment.replace(
                                                                    /\n/g,
                                                                    "<br />"
                                                                )
                                                            )}
                                                        </span>
                                                        <small>
                                                            {formatDate(
                                                                new Date(
                                                                    data?.date
                                                                )
                                                            )}
                                                        </small>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </section>
                                )}
                            </div>
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem uuid="3">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Case Activity Logs
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div className="history">
                            <div className="responsive-table maxed">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Date & Time</th>
                                            {/* <th>Time</th> */}
                                            <th>Operator</th>
                                            <th>Role</th>
                                            <th className="description">
                                                Details
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedCase?.logs
                                            ?.slice()
                                            ?.reverse()
                                            ?.map((data, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="w-200">
                                                            {formatDate(
                                                                new Date(
                                                                    data?.date
                                                                )
                                                            )}
                                                        </td>
                                                        {/* <td>
                                                            {new Date(
                                                                data?.date
                                                            )?.toLocaleTimeString(
                                                                "en-US",
                                                                {
                                                                    timeStyle:
                                                                        "short",
                                                                }
                                                            )}
                                                        </td> */}
                                                        <td>
                                                            {
                                                                data?.operator
                                                                    ?.username
                                                            }
                                                        </td>
                                                        <td>
                                                            {data?.operator?.roles?.join(
                                                                ", "
                                                            )}
                                                        </td>
                                                        <td>{data?.comment}</td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
                {selectedCase?.decision === "Accepted" && (
                    <AccordionItem uuid="4">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Financial Institutes
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            {selectedCase?.institutes?.map((e, i) => (
                                <>
                                    <div className="banklist-wrapper">
                                        <h3 className="flex items-center content-justify-between">
                                            <div className="flex content-justify-center">
                                                {e?.nick || e.bank.name}
                                            </div>
                                        </h3>
                                        <div className="date-wrapper flex items-end content-justify-between">
                                            <div className="flex align-bottom">
                                                <div className="w-200">
                                                    <span>Submission Date</span>
                                                    <DatePicker
                                                        format="dd/MM/y"
                                                        disabled={e.decision}
                                                        className="form-control p-0"
                                                        // onChange={(d) =>
                                                        //     updateDate(d, e._id)
                                                        // }
                                                        value={
                                                            new Date(
                                                                e.submissionDate
                                                            )
                                                        }
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                        }}
                                                    />
                                                </div>
                                                <div className="px-2 w-160">
                                                    <span>
                                                        Submitted Amount
                                                    </span>
                                                    <input
                                                        value={formatCurrency(
                                                            allSubmittedAmounts[
                                                                i
                                                            ],
                                                            true
                                                        )}
                                                        className="form-control"
                                                        placeholder="Submitted Amount"
                                                        disabled
                                                    />
                                                </div>

                                                {e.decision === "Accepted" &&
                                                    e.amount && (
                                                        <div className="px-2 w-160">
                                                            <span>
                                                                Approved Amount
                                                            </span>

                                                            <input
                                                                value={formatCurrency(
                                                                    allApprovedAmounts[
                                                                        i
                                                                    ],
                                                                    true
                                                                )}
                                                                className="form-control"
                                                                placeholder="Approved Amount"
                                                                disabled
                                                            />
                                                        </div>
                                                    )}

                                                {e.cashOut && (
                                                    <div className="px-2 w-160">
                                                        <span>
                                                            Cash Out Amount
                                                        </span>

                                                        <input
                                                            value={formatCurrency(
                                                                e.cashOut,
                                                                true
                                                            )}
                                                            disabled
                                                            className="form-control"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="flex column-direction">
                                                {e.decision && (
                                                    <span
                                                        className={`${
                                                            e.decision ===
                                                                "Accepted" &&
                                                            "completed "
                                                        } case-status`}
                                                    >
                                                        {e.decision} by Bank
                                                    </span>
                                                )}
                                                {e.clientDecision && (
                                                    <span
                                                        className={`${
                                                            e.clientDecision ===
                                                                "Accepted" &&
                                                            "completed "
                                                        } case-status mt-10`}
                                                    >
                                                        {e.clientDecision} by
                                                        Client
                                                    </span>
                                                )}
                                                {e.fundsDisbursed && (
                                                    <div className="disbursed">
                                                        Funds disbursed on{" "}
                                                        {shortFormatDate(
                                                            e.fundsDisbursed
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))}

                            <div className="approved-div">
                                <div>Total approved amount by bank</div>
                                <div>
                                    {formatCurrency(
                                        selectedCase?.institutes?.reduce(
                                            (accumulator, currentObject) => {
                                                const numericAmount =
                                                    parseFloat(
                                                        currentObject.approvedAmount
                                                    ) || 0;
                                                return (
                                                    accumulator + numericAmount
                                                );
                                            },
                                            0
                                        ) || "0",
                                        true
                                    )}
                                </div>
                            </div>
                            <div className="client-approved-div">
                                <div>Total accepted amount by client</div>
                                <div>
                                    {formatCurrency(
                                        selectedCase?.institutes
                                            ?.filter(
                                                (e) =>
                                                    e?.clientDecision ===
                                                    "Accepted"
                                            )
                                            ?.reduce(
                                                (
                                                    accumulator,
                                                    currentObject
                                                ) => {
                                                    const numericAmount =
                                                        parseFloat(
                                                            currentObject.cashOut
                                                        ) || 0;
                                                    return (
                                                        accumulator +
                                                        numericAmount
                                                    );
                                                },
                                                0
                                            ) || "0",
                                        true
                                    )}
                                </div>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                )}
            </Accordion>

            {["Draft", "Pending", "Rejected", "Rejected by Client"].includes(
                selectedCase?.status
            ) &&
                !selectedCase?.rmPdpa &&
                reduxData?.userRoles?.currentRole !== "Lead Coordinator" &&
                !props?.lead && (
                    <div className="button-wrap flex content-justify-center fixed-button-wrap">
                        <button
                            className="simple-btn"
                            onClick={() =>
                                selectedCase?.status === "Pending"
                                    ? waiting(true)
                                    : waiting()
                            }
                        >
                            {selectedCase?.status === "Pending"
                                ? "Back to Draft"
                                : "Waiting for Customer"}
                        </button>
                        <button
                            className="simple-btn manager"
                            onClick={submission}
                        >
                            Submit to Case Manager
                        </button>
                        {selectedCase?.status === "Rejected" &&
                            (reduxData?.userRoles?.currentRole ===
                                "RM Member" ||
                                reduxData?.userRoles?.currentRole ===
                                    "RM Team Leader") &&
                            !props?.lead && (
                                <button
                                    className="simple-btn"
                                    onClick={() => setCompleteModal(true)}
                                >
                                    Abort
                                </button>
                            )}
                    </div>
                )}

            {selectedCase?.status === "Completed" && !selectedCase?.rmPdpa && (
                <button
                    onClick={() => markComplete()}
                    className="colored simple-btn manager w-100"
                >
                    Mark as RM PDPA Complete
                </button>
            )}
        </div>
    );
}
