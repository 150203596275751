import React from "react";
import { useState } from "react";
import { api } from "../../../helpers/apiHelper/requestHelper";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/loader/loadingSlice";
import { toastify } from "../../../helpers/toast/toastify";

export default function AutoReminder() {
    const [reminders, setReminders] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        getSettings();
    }, []);

    const update = (status, reason, value, action) => {
        let rem = reminders.find((e) => e.status === status);
        let res = rem?.reasons?.find((e) => e.reason === reason);

        if (action === "+") {
            if ((parseInt(res.value) + 1)?.toString() > 0) {
                res["value"] = (parseInt(res.value) + 1)?.toString();
            }
        } else {
            if ((parseInt(res.value) - 1)?.toString() > 0) {
                res["value"] = (parseInt(res.value) - 1)?.toString();
            }
        }

        let newReminders = reminders?.map((e) => {
            if (e.status === status) {
                return {
                    ...e,
                    reasons: e.reasons?.map((f) => {
                        if (f?.reason === reason) {
                            return res;
                        } else {
                            return f;
                        }
                    }),
                };
            } else {
                return e;
            }
        });

        setReminders(newReminders);
    };

    const getSettings = async () => {
        dispatch(setLoading(true));
        const response = await api("get", "settings/indexReminders");
        if (response?.status) {
            setReminders(response?.data?.reminders);
        } else {
            toastify(response?.message);
        }
        dispatch(setLoading(false));
    };

    const submit = async () => {
        dispatch(setLoading(true));
        const response = await api("post", "settings/update", {
            reminders,
        });
        if (response?.status) {
            toastify("Reminder changed successfully", "success");
        } else {
            toastify(response?.message);
        }
        dispatch(setLoading(false));
    };
    return (
        <>
            <h2 className="flex flex-wrap mini-title items-center">
                Settings / Auto Reminder
                <button className="simple-btn ml-auto" onClick={submit}>
                    Save
                </button>
            </h2>
            <div className="card">
                <div className="card-body">
                    <div className="responsive-table w-100">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Reason</th>
                                    <th>Time Frame</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reminders?.map((r) => (
                                    <tr>
                                        <td>{r.status}</td>
                                        <td>
                                            {r?.reasons?.map((e) => (
                                                <div className="py-10">
                                                    {e.reason}
                                                </div>
                                            ))}
                                        </td>
                                        <td>
                                            {r?.reasons?.map((e) => (
                                                <div className="my-unique-input-group">
                                                    <div
                                                        className="my-unique-minus"
                                                        onClick={() =>
                                                            update(
                                                                r?.status,
                                                                e?.reason,
                                                                e?.value,
                                                                "-"
                                                            )
                                                        }
                                                    >
                                                        <i className="fas fa-minus"></i>
                                                    </div>
                                                    <input
                                                        className="my-unique-input"
                                                        type="number"
                                                        value={e?.value}
                                                        disabled
                                                    />
                                                    <div
                                                        className="my-unique-plus"
                                                        onClick={() =>
                                                            update(
                                                                r?.status,
                                                                e?.reason,
                                                                e?.value,
                                                                "+"
                                                            )
                                                        }
                                                    >
                                                        <i className="fas fa-plus"></i>
                                                    </div>
                                                    <div className="ml-20 bold">
                                                        Days
                                                    </div>
                                                </div>
                                            ))}
                                        </td>
                                    </tr>
                                ))}
                                {/* <tr className="my-20">
                                    <td>Processing</td>
                                    <td>
                                        <div className="mb-10">
                                            Awaiting Submission to Bank
                                        </div>
                                        <div>Submitted to Bank</div>
                                    </td>
                                    <td>
                                        <div class="my-unique-input-group">
                                            <div class="my-unique-minus">
                                                <i class="fas fa-minus"></i>
                                            </div>
                                            <input
                                                class="my-unique-input"
                                                type="number"
                                                value="0"
                                            />
                                            <div class="my-unique-plus">
                                                <i class="fas fa-plus"></i>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="my-20">
                                    <td>Approved</td>
                                    <td>Accepted by X Bank to Y Bank</td>
                                    <td>
                                        <div class="my-unique-input-group">
                                            <div class="my-unique-minus">
                                                <i class="fas fa-minus"></i>
                                            </div>
                                            <input
                                                class="my-unique-input"
                                                type="number"
                                                value="0"
                                            />
                                            <div class="my-unique-plus">
                                                <i class="fas fa-plus"></i>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="my-20">
                                    <td>Completed</td>
                                    <td>Approved by Client, choice X Bank</td>
                                    <td>
                                        <div class="my-unique-input-group">
                                            <div class="my-unique-minus">
                                                <i class="fas fa-minus"></i>
                                            </div>
                                            <input
                                                class="my-unique-input"
                                                type="number"
                                                value="0"
                                            />
                                            <div class="my-unique-plus">
                                                <i class="fas fa-plus"></i>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="my-20">
                                    <td>Rejected</td>
                                    <td>
                                        <div className="mb-10">
                                            Rejected by Client
                                        </div>
                                        <div className="mb-10">
                                            Rejected by Case Manager in
                                            Submission
                                        </div>
                                        <div>
                                            Rejected by Case Manager due to Bank
                                        </div>
                                    </td>
                                    <td>
                                        <div class="my-unique-input-group">
                                            <div class="my-unique-minus">
                                                <i class="fas fa-minus"></i>
                                            </div>
                                            <input
                                                class="my-unique-input"
                                                type="number"
                                                value="0"
                                            />
                                            <div class="my-unique-plus">
                                                <i class="fas fa-plus"></i>
                                            </div>
                                        </div>
                                    </td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}
