import add from "../../../../assets/images/add-icon.svg";
import addLink from "../../../../assets/images/form-cross.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import AddressMultipleFields from "../../../admin/users/edit-user-details/AddressMultipleFields";
import addIcon from "../../../../assets/images/add-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { missingPopup } from "../../../../helpers/dataHelper/missingData";
import { api } from "../../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../../helpers/toast/toastify";
import Multiselect from "multiselect-react-dropdown";
import { setLoading } from "../../../../redux/loader/loadingSlice";

export default function AddBusinessEntity() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [name, setName] = useState("");
    const [uen, setUen] = useState("");
    const [email, setEmail] = useState("");
    const [contact, setContact] = useState("");
    const [rep, setRep] = useState();
    const [inputFields, setInputFields] = useState([]);
    const [customerInterests, setCustomerInterests] = useState([]);
    const [address, setAddress] = useState("");
    const [customers, setCustomers] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const reduxData = useSelector((state) => state);
    const dispatch = useDispatch();
    const numberRegex = new RegExp(`^[0-9]*$`);

    useEffect(() => {
        if (state?.navigate) {
            setRep(state?.user?._id);
            setName(state?.name);
            setUen(state?.uen);
            setEmail(state?.email);
            setContact(state?.contact || "");
            setAddress(state?.address);
            setInputFields(state?.inputFields || []);
            setCustomerInterests(
                state?.customerInterests?.map((e) => {
                    return getProducts(e);
                })
            );
            setCustomers(state?.customers || []);
            setSuppliers(state?.suppliers || []);
        }
    }, [state?.navigate]);

    useEffect(() => {
        if (state?.entity) {
            let data = state?.entity;
            setName(data?.name);
            setUen(data?.uen);
            setEmail(data?.email);
            setContact(data?.contact || "");
            setRep(data?.representative?._id);
            setAddress(
                data?.addresses && data?.addresses?.length
                    ? data?.addresses[0]
                    : []
            );
            setInputFields(getInputFields(data?.addresses));
            setCustomerInterests(
                data?.customerInterests?.map((e) => {
                    return getProducts(e);
                })
            );
            setCustomers(data?.customers || []);
            setSuppliers(data?.suppliers || []);
        }
    }, [state?.entity]);

    const getInputFields = (data) => {
        if (data?.length > 1) {
            let newData = data.filter((e, i) => i > 0);
            return newData;
        } else {
            return [];
        }
    };

    const handleSelect = (selectedList) => {
        setCustomerInterests(selectedList);
    };

    const handleRemove = (selectedList) => {
        setCustomerInterests(selectedList);
    };

    const getProducts = (pr) => {
        let list = reduxData?.productForms?.value;
        return list?.find((e) => e._id === pr);
    };

    const submit = async () => {
        if (!name) missingPopup("Company Name");
        else if (!rep) missingPopup("Representative");
        else if (!uen) missingPopup("UEN");
        else if (!email) missingPopup("Email");
        else if (!contact) missingPopup("Contact");
        else {
            if (inputFields?.length) {
                var allAddresses = inputFields?.filter((e) => e !== "");
                allAddresses?.push(address);
            }
            let data = {
                name,
                uen,
                email,
                representative: rep,
                contact,
                addresses: inputFields?.length ? allAddresses : [address],
                customerInterests: customerInterests?.map((e) => e._id),
                customers,
                suppliers,
            };

            if (state?.entity) {
                dispatch(setLoading(true));
                const response = await api("post", "businessEntity/update", {
                    ...data,
                    id: state?.entity?._id,
                });
                dispatch(setLoading(false));

                if (response?.data?.status) {
                    toastify("Business Entity Updated Successfully", "success");
                    if (reduxData?.userRoles?.currentRole === "RM Team Leader")
                        if (state?.redirectData) {
                            navigate("/rm-lead/add-leads", {
                                state: {
                                    redirectData: {
                                        ...state?.redirectData,
                                        entity: response?.data?.data,
                                    },
                                },
                            });
                        } else if (state?.navigate_lead) {
                            navigate("/rm-lead/add-leads", {
                                state: {
                                    ...state,
                                    entity: response?.data?.data,
                                },
                            });
                        } else {
                            navigate("/rm-lead/entities");
                        }
                    else if (reduxData?.userRoles?.currentRole === "RM Member")
                        if (state?.redirectData) {
                            navigate("/rm-member/add-leads", {
                                state: {
                                    redirectData: {
                                        ...state?.redirectData,
                                        entity: response?.data?.data,
                                    },
                                },
                            });
                        } else if (state?.navigate_lead) {
                            navigate("/rm-member/add-leads", {
                                state: {
                                    ...state,
                                    entity: response?.data?.data,
                                },
                            });
                        } else {
                            navigate("/rm-member/entities");
                        }
                    else if (
                        reduxData?.userRoles?.currentRole === "Lead Coordinator"
                    )
                        if (state?.redirectData) {
                            navigate("/lead-coordinator/add-leads", {
                                state: {
                                    redirectData: {
                                        ...state?.redirectData,
                                        entity: response?.data?.data,
                                    },
                                },
                            });
                        } else if (state?.navigate_lead) {
                            navigate("/lead-coordinator/add-leads", {
                                state: {
                                    ...state,
                                    entity: response?.data?.data,
                                },
                            });
                        } else {
                            navigate("/lead-coordinator/entities");
                        }
                } else {
                    toastify(response?.message);
                }
            } else {
                dispatch(setLoading(true));

                const response = await api(
                    "post",
                    "businessEntity/create",
                    data
                );
                dispatch(setLoading(false));

                if (response?.data?.status) {
                    toastify("Business Entity Added Successfully", "success");

                    if (state?.navigate_lead) {
                        if (
                            reduxData?.userRoles?.currentRole ===
                            "RM Team Leader"
                        )
                            navigate("/rm-lead/add-leads", {
                                state: {
                                    state: {
                                        ...state,
                                        entity: response?.data?.data,
                                    },
                                },
                            });
                        else if (
                            reduxData?.userRoles?.currentRole === "RM Member"
                        )
                            navigate("/rm-member/add-leads", {
                                state: {
                                    state: {
                                        ...state,
                                        entity: response?.data?.data,
                                    },
                                },
                            });
                        else if (
                            reduxData?.userRoles?.currentRole ===
                            "Lead Coordinator"
                        )
                            navigate("/lead-coordinator/add-leads", {
                                state: {
                                    state: {
                                        ...state,
                                        entity: response?.data?.data,
                                    },
                                },
                            });
                    } else {
                        if (
                            reduxData?.userRoles?.currentRole ===
                            "RM Team Leader"
                        )
                            navigate("/rm-lead/entities");
                        else if (
                            reduxData?.userRoles?.currentRole === "RM Member"
                        )
                            navigate("/rm-member/entities");
                        else if (
                            reduxData?.userRoles?.currentRole ===
                            "Lead Coordinator"
                        )
                            navigate("/lead-coordinator/entities");
                    }
                } else {
                    toastify(response?.message);
                }
            }
        }
    };

    return (
        <>
            <h2 className="flex flex-wrap mini-title">
                <span
                    onClick={() =>
                        navigate(
                            "/" +
                                window.location.pathname.split("/")[1] +
                                "/entities"
                        )
                    }
                >
                    Entity /
                </span>{" "}
                {state?.entity ? "Edit " : "Add "}Business Entity
            </h2>
            <div className="card">
                <div className="card-title">
                    <h2>Business Entity</h2>
                </div>
                <div className="card-body">
                    <div className="edit-form-wrapper">
                        <ul className="list-style-none flex flex-wrap">
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Company Name *</label>
                                    <input
                                        type="text"
                                        placeholder="Enter Company Name"
                                        className="form-control mandatory"
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">UEN *</label>
                                    <input
                                        type="text"
                                        placeholder="UEN"
                                        className="form-control mandatory"
                                        value={uen}
                                        onChange={(e) => setUen(e.target.value)}
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Company Email *</label>
                                    <input
                                        type="text"
                                        placeholder="Company email"
                                        className="form-control mandatory"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label
                                        className="items-center"
                                        style={{ display: "flex" }}
                                        htmlFor=""
                                    >
                                        Representative *
                                        {!state?.redirectData && (
                                            <div
                                                className="flex items-center links cursor-pointer"
                                                onClick={() => {
                                                    if (
                                                        reduxData?.userRoles
                                                            .currentRole ===
                                                        "RM Team Leader"
                                                    ) {
                                                        navigate(
                                                            "/rm-lead/individual-entity",
                                                            {
                                                                state: {
                                                                    navigate: true,
                                                                    name,
                                                                    uen,
                                                                    email,
                                                                    contact,
                                                                    address,
                                                                    inputFields,
                                                                },
                                                            }
                                                        );
                                                    } else if (
                                                        reduxData?.userRoles
                                                            .currentRole ===
                                                        "RM Member"
                                                    ) {
                                                        navigate(
                                                            "/rm-member/individual-entity",
                                                            {
                                                                state: {
                                                                    navigate: true,
                                                                    name,
                                                                    uen,
                                                                    email,
                                                                    contact,
                                                                    address,
                                                                    inputFields,
                                                                },
                                                            }
                                                        );
                                                    } else if (
                                                        reduxData?.userRoles
                                                            .currentRole ===
                                                        "Lead Coordinator"
                                                    ) {
                                                        navigate(
                                                            "/lead-coordinator/individual-entity",
                                                            {
                                                                state: {
                                                                    navigate: true,
                                                                    name,
                                                                    uen,
                                                                    email,
                                                                    contact,
                                                                    address,
                                                                    inputFields,
                                                                },
                                                            }
                                                        );
                                                    }
                                                }}
                                            >
                                                <img
                                                    src={addLink}
                                                    alt="addLink"
                                                />
                                                Add Individual
                                            </div>
                                        )}
                                    </label>
                                    <select
                                        className="form-control mandatory"
                                        value={rep}
                                        onChange={(e) => setRep(e.target.value)}
                                    >
                                        <option style={{ display: "none" }}>
                                            Select
                                        </option>
                                        {reduxData?.individualEntities?.value?.map(
                                            (e) => (
                                                <option value={e._id}>
                                                    {e.firstName +
                                                        "  " +
                                                        e?.lastName}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Contact Number *</label>
                                    <input
                                        type="text"
                                        placeholder="Contact Number"
                                        className="form-control contact mandatory"
                                        value={contact}
                                        onChange={(e) => {
                                            if (
                                                numberRegex.test(
                                                    e.target.value
                                                ) ||
                                                e.target.value === ""
                                            ) {
                                                setContact(e.target.value);
                                            }
                                        }}
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Customer Interests</label>
                                    <Multiselect
                                        isObject={true}
                                        showCheckbox
                                        options={reduxData?.productForms?.value}
                                        selectedValues={customerInterests}
                                        onSelect={handleSelect}
                                        onRemove={handleRemove}
                                        displayValue="name"
                                    />
                                </div>
                            </li>

                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Customer 1</label>
                                    <input
                                        type="text"
                                        placeholder="Enter Customer"
                                        className="form-control"
                                        value={customers[0]}
                                        onChange={(e) => {
                                            let arr = [...customers];
                                            arr[0] = e.target.value;
                                            setCustomers(arr);
                                        }}
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Customer 2</label>
                                    <input
                                        type="text"
                                        placeholder="Enter Customer"
                                        className="form-control"
                                        value={customers[1]}
                                        onChange={(e) => {
                                            let arr = [...customers];
                                            arr[1] = e.target.value;
                                            setCustomers(arr);
                                        }}
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Supplier 1</label>
                                    <input
                                        type="text"
                                        placeholder="Enter Supplier"
                                        className="form-control"
                                        value={suppliers[0]}
                                        onChange={(e) => {
                                            let arr = [...suppliers];
                                            arr[0] = e.target.value;
                                            setSuppliers(arr);
                                        }}
                                    />
                                </div>
                            </li>

                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Supplier 2</label>
                                    <input
                                        type="text"
                                        placeholder="Enter Supplier"
                                        className="form-control"
                                        value={suppliers[1]}
                                        onChange={(e) => {
                                            let arr = [...suppliers];
                                            arr[1] = e.target.value;
                                            setSuppliers(arr);
                                        }}
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <div className="flex">
                                        <label htmlFor="" className="flex">
                                            Address 1
                                            {inputFields.length === 0 && (
                                                <button
                                                    className="add-address-btn"
                                                    onClick={() => {
                                                        if (
                                                            inputFields[
                                                                inputFields.length -
                                                                    1
                                                            ] !== "" &&
                                                            address !== ""
                                                        ) {
                                                            setInputFields([
                                                                ...inputFields,
                                                                "",
                                                            ]);
                                                        }
                                                    }}
                                                >
                                                    <img
                                                        src={addIcon}
                                                        alt="addIcon"
                                                    />
                                                </button>
                                            )}
                                        </label>
                                    </div>
                                    <input
                                        type="text"
                                        value={address}
                                        onChange={(e) =>
                                            setAddress(e.target.value)
                                        }
                                        className="form-control"
                                        placeholder="Address 1"
                                    />
                                </div>
                            </li>
                            {inputFields?.length > 0 && (
                                <li className="w-100">
                                    <AddressMultipleFields
                                        inputFields={inputFields}
                                        setInputFields={setInputFields}
                                    />
                                </li>
                            )}

                            <li className="flex w-100">
                                <button
                                    className="simple-btn cancel"
                                    onClick={() => {
                                        if (state?.redirectData) {
                                            navigate(-1, {
                                                state: state?.redirectData,
                                            });
                                        } else {
                                            if (state?.navigate_lead) {
                                                if (
                                                    reduxData?.userRoles
                                                        .currentRole ===
                                                    "RM Team Leader"
                                                ) {
                                                    navigate(
                                                        "/rm-lead/add-leads"
                                                    );
                                                } else if (
                                                    reduxData?.userRoles
                                                        .currentRole ===
                                                    "RM Member"
                                                ) {
                                                    navigate(
                                                        "/rm-member/add-leads"
                                                    );
                                                } else if (
                                                    reduxData?.userRoles
                                                        .currentRole ===
                                                    "Lead Coordinator"
                                                ) {
                                                    navigate(
                                                        "/lead-coordinator/add-leads"
                                                    );
                                                }
                                            } else {
                                                if (
                                                    reduxData?.userRoles
                                                        .currentRole ===
                                                    "RM Team Leader"
                                                ) {
                                                    navigate(
                                                        "/rm-lead/entities"
                                                    );
                                                } else if (
                                                    reduxData?.userRoles
                                                        .currentRole ===
                                                    "RM Member"
                                                ) {
                                                    navigate(
                                                        "/rm-member/entities"
                                                    );
                                                } else if (
                                                    reduxData?.userRoles
                                                        .currentRole ===
                                                    "Lead Coordinator"
                                                ) {
                                                    navigate(
                                                        "/lead-coordinator/entities"
                                                    );
                                                }
                                            }
                                        }
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="simple-btn submit"
                                    onClick={submit}
                                >
                                    Submit
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
